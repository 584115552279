.addresses-and-stores-container {
  margin-bottom: 65px;

  .text-transform-capitalize {
    text-transform: capitalize;
  }

  .delivery-store-name {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media #{$breakpoint-below-tablet} {
      margin-right: 0;
    }
  }

  .fl-rgt {
    float: right;
  }

  .header-text {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #202124;

    @media #{$breakpoint-below-tablet} {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 1px;
      margin: 30px 0 23px 30px;
    }
    @media #{$mobile-only} {
     margin-left: 20px;
    }
  }

  .error-service-msg {
    text-align: center;
    color: #f00;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .p-none {
    pointer-events: none;
    border-bottom: none !important;
  }

  .flex-dir {
    align-items: center;
    @media #{$breakpoint-below-tablet} {
      flex-direction: column;
    }
  }

  .commonFont {
    font-family: $National2Regular;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #202124;
  }

  .bgv-set {
    background: #f8f7f5 !important;
  }

  .anchor-info {
    color: #202124 !important;
    font-weight: 500;
    font-family: $National2Medium;
    font-size: 14px;
    line-height: 24px;
    text-decoration: underline !important;
    margin: 5px 0;
  }

  .font-bold {
    font-weight: bold;
  }

  .blkBorder {
    border: 1.5px solid #202124;
    margin-left: 5px;
    background: #f8f7f5;
  }

  .blkBorder:hover {
    cursor: pointer;
    background-color: #202124;
    color: #fff;
  }

  .align-center {
    align-items: center;
  }

  .text-center {
    text-align: center;
    margin: 15px 0;
  }

  .mt-10 {
    margin: 10px 0;
  }

  .ml5 {
    margin-left: 5% !important;
  }
  .ml2 {
    margin-left: 2% !important;
  }

  .ml-0 {
    @media #{$breakpoint-below-tablet} {
      margin-left: 0 !important;
    }
  }

  .mt5 {
    margin-top: 10px;

    img {
      height: 24px;
    }

    @media #{$breakpoint-below-tablet} {
      margin-top: 5px;
    }
  }

  .favorite-stores-section {
    .more-info-btn {
      margin-right: 25px;
      border: 1.5px solid $Brand-Black;
    }
    .close-cross-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 4%;
    }
    .fav-store-block {
      justify-content: flex-start !important;
    }

    .no-fav-store {
      margin: 20px 0px 60px 0px;
        color: $Dark-Gray;
        p {
          font-size: 18px;
          line-height: 30px;
          color: #494949;
          letter-spacing: -0.1px;
          font-family: $National2Regular;
          @media #{$mobile-only} {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: -0.1px;
            color: #494949;
          }
        @media #{$breakpoint-below-tablet} {
          margin-left: 30px;
        }
      }
    }
    @media #{$breakpoint-below-tablet} {
      margin: 15px 0 60px 0;
    }

    .store-card {
      padding: 3% 4%;

      @media #{$breakpoint-below-desktop-above-tablet} {
        padding: 3% 3%;
      }
      @media #{$mobile-only} {
        padding: 3% 5%;
      }
    }

    .facilities {
      flex-wrap: wrap;
    }

    .takeawayDineInMap {
      height: 350px !important;
      margin: 3% 0;

      @media #{$breakpoint-below-tablet} {
        height: auto !important;

        #google-map {
          height: 350px !important;
        }
      }

      #google-map {
        height: inherit;
      }

      .arrows {
        position: absolute;
        display: flex;
        bottom: 0 !important;
        right: 0;
        top: 400px;
      }
    }

    .Selected-desktop-Card {
      width: 340px;
      position: absolute;
      flex-direction: column;
      padding: 1% 1%;
      margin: 0;

      .address-block,
      .button-block {
        justify-content: flex-end;
        width: 100%;
      }

      .flex-dir-pos {
        flex-direction: column;
      }
    }

    .map-link-list {
      float: right;
      color: #202124 !important;
      text-transform: capitalize;
      text-decoration: underline;
      @media #{$mobile-only} {
        padding-right: 10px;
      }
      @media #{$breakpoint-ipad-mini-portrait} {
        padding-right: 10px;
      }
      @media #{$breakpoint-samsung-galaxy-tab-s7} {
        padding-right: 10px;
      } 
      
    }

    .address-block {
      @media #{$breakpoint-ipad-12-pro-portrait} {
        width: 65%;
      }

      @media #{$breakpoint-below-tablet} {
        width: 100%;
      }

      .card-content {
        & .more-info-link {
          font-size: 0.875em;
          color: #202124;
          text-decoration: underline;
          padding-right: 15px;
          outline: none;
          border: none;
          background: none;
          font-weight: 500;
          font-style: $National2Medium;
        }
        & .more-info-details{
          margin-bottom: 10px;
        }
      }

      .favorite-img {
        cursor: pointer;
      }

      .fav-img-mobile {
        position: absolute;
        float: right;
        top: 20.2px;
        right: 32.2px;
      }

      .card-store-container {
        @media #{$breakpoint-below-tablet} {
          display: flex;
        }
        .card-store-global {
          @media #{$breakpoint-below-tablet} {
            width: auto;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            @media #{$mobile-only} {
              max-width: 68%;
            }
          }
        }
      }

      .store-distance {
        margin-left: 4%;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #494949;
        @media #{$breakpoint-tablet-only} {
          padding-top: 2%;
        }
      }
    }

    .button-block {
      justify-content: flex-end;

      @media #{$breakpoint-below-tablet} {
        width: 100%;
        justify-content: flex-start;
      }

      button {
        line-height: 24px !important;
        @media #{$mobile-only} {
          width: 100%;
          margin-bottom: 15px;
        }
        @media #{$breakpoint-below-tablet} {
          margin-left: 0 !important;
        }
        @media #{$breakpoint-above-tablet} {
          font-size: 13px;
          padding: 10px;
          width: 163px;
          -webkit-text-size-adjust: 100%;
        }
      }
    }


    .schedule-pad {
      padding: 10px 17px;

      @media #{$breakpoint-above-desktop} {
        padding: 10px 14px;
      }

      @media #{$breakpoint-below-desktop-above-tablet} {
        padding: 10px 4px;
      }
    }

    .order-pad {
      padding: 10px 28px;

      @media #{$breakpoint-below-desktop-above-tablet} {
        padding: 10px 10px;
        width: 120px !important;
      }
    }

    .browse-pad {
      padding: 10px 24px;
    }

    .padding-set {
      @media #{$breakpoint-below-tablet} {
        padding: 10px 17px;
        width: 150px;
      }
    }

    .margin-adjust {
      @media #{$breakpoint-below-tablet} {
        margin-left: 0 !important;
        margin-right: 5px;
      }
    }

    .ml5 {
      margin-left: 5px;
    }

    .info-timing {
      font-family: $National2Medium;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      margin-top: 10px;
      padding-bottom: 10px;
    }

    .list-circle {
      display: list-item;
      color: $Red-Orange;
      margin-left: 23px;
      left: 19px;
      @media #{$breakpoint-ipad-12-pro-portrait} {
        width: 190px;
      }
    }
  }

  .delivery-address-section {
    .no-fav-store {
      color: $Dark-Gray;
      margin: 20px 0px;
      p {
        font-size: 18px;
        line-height: 30px;
        color: #494949;
        letter-spacing: -0.1px;
        font-family: $National2Regular;
        @media #{$mobile-only} {
          @media #{$mobile-only} {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: -0.1px;
            color: #494949;
          }
        }
        @media #{$breakpoint-below-tablet} {
          margin-left: 30px;
        }
      }
    }

    .address-section {
      margin-top: 20px;
    }
    .add-address {
      @media #{$breakpoint-below-tablet} {
        margin-top: 20px;
        text-align: center;
      }
    }

    .d-flex {
      display: flex;
    }

    .delivery-card {
      margin: 10px 0;
      padding: 25px 30px;

      @media #{$breakpoint-below-tablet} {
        padding: 21px 30px;
      }
      @media #{$mobile-only} {
        padding-left: 20px;
       }
    }

    .address-description {
      align-items: center;
      width: 80%;

      @media #{$breakpoint-below-tablet} {
        flex-direction: column;
        align-items: unset;
        width: 70%;
      }

      .address-mode {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        margin: 0;
        color: #202124;
        width: 30%;

        @media #{$breakpoint-below-tablet} {
          width: 100%;
        }
      }

      .address-mode-global {
        display: flex;
        span:nth-child(2){
          margin-left: 5px;
        }
      }

      .delivery-details {
        margin-left: 20px;
        width: 70%;
        display: flex;
        flex-direction: column;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Brand-Black;
        @media #{$breakpoint-below-tablet} {
          width: 100%;
          margin-left: 0;
        }

        .address-heading {
          text-transform: capitalize;
        }
        .address-street-text {
          word-break: break-word;
        }
        .address-text {
          text-transform: initial;
        }
        .address-text-Instructions {
          text-transform: initial;
          word-break: break-word;
        }

        div {
          @extend .commonFont;

          font-weight: 400;
        }
      }
    }

    .action-btn {
      align-items: center;
      margin-left: 12px;
      width: 20%;

      @media #{$breakpoint-below-tablet} {
        width: 30%;
        align-items: unset;
        margin-left: 0;
      }

      .anchor-info {
        margin: 5px 20px;
        @media #{$breakpoint-ipad-12-pro-portrait} {
          margin: 5px 12px;
        }
      }

      .edit-adjust {
        @media #{$breakpoint-below-tablet} {
          margin-right: 15%;
          margin-left: 0;
        }
      }

      .del-adjust {
        @media #{$breakpoint-below-tablet} {
          margin-left: 5%;
        }
      }
    }
  }

  .address-module-modal {
    .address-form-section {
      background: #f8f7f5;
      margin: 30px 20px 0 20px !important;
      padding-top: 15px !important;

      @media #{$breakpoint-below-tablet} {
        margin: 20px 0 !important;
        padding-bottom: 0 !important;
      }
    }

    .header-title {
      margin: unset !important;
      left: 13px;
      position: relative;
      font-family: $National2Condensed !important;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #202124;

      @media #{$breakpoint-below-tablet} {
        font-family: $National2Medium !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        top: 2px;
        text-align: center;
        color: #494949;
        left: 0;
        width: 100%;
        text-transform: capitalize;
      }
    }

    .modal-content {
      width: 40% !important;
      left: 30% !important;

      @media #{$breakpoint-ipad-12-pro-portrait} {
        width: 50% !important;
        left: 25% !important;
      }

      @media #{$breakpoint-below-tablet} {
        width: 100% !important;
        left: 0 !important;
      }

      .modal-header {
        @media #{$breakpoint-below-tablet} {
          text-align: center;
        }
        .header-title{
          font-size: 18px;
          font-weight: 700;
          line-height: 25px;
				@media #{$mobile-only}{
					font-size: 14px;
          line-height: 24px;
          font-weight: 400;
				}
			}
      }
    }

    .modal-body {
      background: unset !important;
      padding: 0 !important;
    }

    .blkColor {
      background: #202124;
      color: #fff;

      @extend .commonFont;

      width: 125px;
      font-weight: 500;
    }

    .whiteButton.blkColor:disabled,
    .whiteButton.blkColor:hover:disabled {
      background-color: #dbdbdb;
      color: #494949;
      cursor: not-allowed;
      border: none;
    }

    .blkColor:hover {
      cursor: pointer;
      background-color: #fff;
      color: #202124;

      @extend .commonFont;

      font-weight: 500;
      border: 1.5px solid #202124;
    }

    .form-container {
      margin: 0 40px;

      @media #{$breakpoint-below-tablet} {
        margin: 0 20px;
      }

      .form-header {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #202124;
      }

      .address-list {
        top: 40px;
        border-radius: 4px;
        position: absolute;
        z-index: 99;
        height: auto;
        list-style: none;
        overflow-y: auto;
        width: 100%;
        padding: 0;
        background-color: $Mashed-Potato-Gray;

        .address-suggestion-text {
          font-size: 12px;
          background-color: $Brand-Black;
          color: white;
          font-family: $National2Regular;
          padding: 1% 1% 1% 1%;
          cursor: pointer;

          .suggetion-pin {
            margin-right: 10px;
          }
        }
      }

      form {
        margin-top: 42px;

        label {
          display: inline-block;
          transition: all 0.2s ease;
          z-index: 10;
          position: relative;
          top: -30px;
          font-weight: 400;

          @extend .commonFont;
        }

        input[type='text'],
        select {
          -webkit-appearance: none;
          appearance: none;
          border-radius: 0;

          width: 100%;
          position: relative;
          display: inline-block;
          padding: 0 0 5px 0;
          background-color: transparent;
          border: none;
          border-bottom: solid 1.5px #202124;
          outline: none;
          z-index: 20;
          font-weight: 500;

          @extend .commonFont;
        }

        .default-address-section {
          display: flex;
          align-items: center;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          margin-top: -25px;
          padding-bottom: 30px;

          input {
            -webkit-appearance: none;
            appearance: none;
            height: 16px;
            width: 16px;
            min-width: 16px;
            // background: #202124;
            border-radius: none;
            cursor: pointer;
            outline: none;
            border: 1.5px solid #494949;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 99;
          }

          input[type='checkbox']:checked::after {
            display: block;
          }

          input[type='checkbox']::after {
            content: '✓';
            color: white;
            font-weight: 900;
            font-size: 12px;
            display: none;
          }

          .default-label-text {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            label {
              margin: 0;
              padding: 0;
              cursor: pointer;
              top: 1px;
            }
          }
        }

        .input-container {
          position: relative;
          margin: 10px 0;

          input {
            font-family: $National2Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #202124;
          }

          .error-input {
            color: $Red-Orange;
          }
        }

        select {
          height: 30px;
          font-weight: bold !important;
          font-family: $National2Regular;
          background-image: url("../../../../src/styles/staticAssets/images/Accordian_Arrow.png");
          background-repeat: no-repeat;
					background-position: right;
          option {
            font-weight: bold !important;
            font-family: $National2Regular;
          }         
        }
        
        .adjust-width {
          .input-container {
            width: 50%;

            @media #{$breakpoint-below-tablet} {
              width: 100%;
              margin-left: 0 !important;
            }
          }
        }

        .adjust-pos {
          margin-left: 6px;
          margin-top: -4px;
        }

        input[type='checkbox']:checked {
          background-color: black;
          color: white;
        }

        input[type='text']:focus ~ label,
        select:focus ~ label,
        select:valid ~ label,
        input[type='text']:valid ~ label {
          top: -55px;
          font-size: 12px;
          line-height: 22px;
          color: #494949;
        }
      }
    }
  }
}

.coupon-toast-position-address-comp {
  min-width: 135px;
  .success-coupon-toast {
    min-width: 0;
  }
}

.error-text-container {
  position: absolute;
  left: 0;
  top: 35px;
  .error-text {
    margin-left: 5px;
    color: $Red-Orange;
  }
}

.error-input-container {
  margin-bottom: 20px !important;
}

.addr-error-container {
  border-radius: 5px;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #ff2e00;
  margin: 20.5px 21px -10px 20px !important;
  padding: 23px 0 !important;
  background: #fff4f2;

  & .error-img {
    background: url('../../staticAssets/images/Error.svg') no-repeat 100% 100%;
    height: 16px;
    width: 19px;
    display: block;
    margin: auto;
  }

  .error-sub-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 20.59px;
    @media #{$breakpoint-below-tablet} {
      align-items: flex-start;
    }
  }

  .error-msg {
    margin-left: 9.29px;
    color: $Brand-Black;
    @media #{$breakpoint-below-tablet} {
      margin-top: -5px;
      text-align: start;
    }
  }
}

.favorite-img {
  cursor: pointer;
  @media #{$mobile-only} {
    top: 15px;
    right: 2px;
    position: absolute;
    }
}

.edit-addr-model {
  .custom-modal-close {
    top: 12px !important;
    padding-right: 5px;
  }

  .error-container {
    @media #{$breakpoint-iphone-six-plus} {
      padding: 40px 0 !important;
    }
  }
}
.caretIcon.store-timing-msg {
  cursor: pointer;
}
.hoursHiddenArrow.caretIcon img {
  transform: rotate(180deg);
}
.hours-view{
  width: 100%;
  &.hoursHidden {
    display: none;
  }
  .dispotion-hours-text{
    font-family: $National2Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #202124;
    margin: 12px 0 7px 0;
  }
  .hours-text{
    width: 100%;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .day-details{
    width: 50%;
  }
  .time-details{
    width: 50%;
    text-align: right;
  }
}
