.promos-container {
	background-color: $white;
	overflow: hidden;
	
	& .promosCard-container {
		//margin-left: 12%;
		@media #{$breakpoint-above-medium-desktop}{      
      padding-left: 0.5rem !important;
		}
		@media #{$breakpoint-below-tablet} {
			margin: 0;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		& .promos-Icon {
			margin-bottom: 30px;	
			@media #{$breakpoint-above-medium-desktop}{      
				margin-left: -0.4% !important;
			}
			@media #{$breakpoint-medium-desktop} {
				margin-left: -2px !important;
			}
			& .promosItem-icon {
				padding: 0 1%;
			}

			@media #{$breakpoint-below-tablet} {
				margin-left: 0%;
			}
		}

		& .promosContainerHeader-items {
			color: $Brand-Black;
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			margin-left: 1%;
			justify-content: space-between;
			@media #{$breakpoint-below-tablet} {
				margin-left: 0%;
			}
			@media #{$breakpoint-above-desktop} {
				margin-right: 18%;
			}
			@media #{$breakpoint-above-medium-desktop}{      
				margin-left: 0.8% !important;
			}
			& .view-all-text {
			
				@media #{$breakpoint-below-desktop} {
					margin-right: 15%;
				}

				@media #{$breakpoint-between-tablet-desktop} {
					margin-right: 14%;
				}

				@media #{$breakpoint-below-tablet} {
					margin-right: 0;
					height: 28px;
					margin-bottom:24px;
					// margin-top: 15px;
				}
			}

		

			@media #{$breakpoint-below-tablet} {
				flex-direction: column;
			}

			& .promosContainer-header {
				// margin-right: 652px;
				font-family: $National2Condensed;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 38px;
				line-height: 43px;
				font-weight: bold;

				@media #{$breakpoint-below-tablet} {
					width: 100%;
					//height: 0px;
					font-size: 24px;
					line-height: 32px;
					//margin: 0;
					margin-bottom: 10px;
				}
			}

			& .promosViewAll-link {
				font-family: $National2Regular;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				color: $white;
				text-decoration: none;
				margin-right: 10px;

				&:hover {
					cursor: pointer;
				}
			}

			& .promosViewAlldeals-arrow {
				display: inline-block;
			}
		}

		& .plp-promosContainerHeader-items{
			margin-left: -1% !important;
			margin-bottom: 40px;
			@media #{$breakpoint-above-desktop} {
				margin-left: 0%;
			}
			@media #{$breakpoint-below-tablet} {
				margin-left: 10px !important;
				margin-bottom: 20px;
				align-items: start;
			}

			.promosContainer-header{
				margin-bottom: 0;
			}

			.promosHeader-text{
				margin-top: 5px;
			}
		}

		& .promosCard-list {
			display: flex;
			padding: '5px 0';
			flex-direction: column;
			//margin-left: 4.2%;

			@media #{$breakpoint-below-tablet} {
				width: 100%;
				margin-left: -5.2%;
			}

			& .promos-slideContainer {
				@media #{$breakpoint-above-desktop} {
					width: 81%;
					margin-left: 15px;
				}
	
				@media #{$breakpoint-below-desktop} {
					width: 84%;
					margin-left: 15px;
				}
	
				@media #{$breakpoint-between-tablet-desktop} {
					width: 84%;
				}

				@media #{$breakpoint-below-tablet} {
					display: none;
				}

				& .promos-slider {
					width: 100%;
					height: 3px;
					-webkit-appearance: none;
					background: $Light-Gray;
					outline: none;
					border-radius: 5%;
					-webkit-transition: 0.2s;
					transition: opacity 0.2s;
				}

				& .promos-slider::-webkit-slider-thumb {
					-webkit-appearance: none;
					appearance: none;
					width: 10%;
					height: 3px;
					border-radius: 5%;
					background: $Brand-Black;
					cursor: pointer; /* Cursor on hover */
				}

				& .promos-slider::-moz-range-thumb {
					-webkit-appearance: none;
					appearance: none;
					width: 10%;
					height: 2px;
					border-radius: 5%;
					background: $Brand-Black;
					cursor: pointer; /* Cursor on hover */
					border: 0;
				}
			}

			& .plp-promos-slideContainer{
				margin-left: 0;
				width: 95%;

				@media #{$breakpoint-between-tablet-desktop} {
					width: 99%;
				}
			}

			& .promosCarousal-list {
				margin-bottom: 3%;
				//margin-left: -15px;
				@media #{$breakpoint-below-tablet} {
					//margin-bottom: 23%;
					//margin-left: 0px;
				}

				& .slick-prev {
					left: -70px;

					@media #{$breakpoint-below-tablet} {
						z-index: 1;
						left: 0;
					}
				}

				& .slick-prev::before {
					font-family: unset;
					font-size: 36px;
					content: '\2039'; // \203A HTML Entry for ‹
					color: #202124;
					background-color: white;
					border-radius: 50%;
					display: inline-block;
					width: 48px;
					height: 48px;
					text-align: center;
					box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
					//border-radius: 66px;
					//transform: rotate(-180deg);
				}

				//& .slick-prev:focus::before {
					//outline: 1px auto -webkit-focus-ring-color;
				//}

				& .slick-list {
					
					padding-left: 0.5rem;

					@media #{$breakpoint-below-tablet} {
						padding-left: 0;
					}

					& .slick-track {
						margin-left: 0;

						@media #{$breakpoint-below-tablet} {
							margin-left: 20px;							
							height: 440px;
						}

						& .lastpromosCard-fade {
							opacity: 0.5;
							@media #{$breakpoint-below-tablet} {
								opacity: 1;
							}
						}

						& .slick-slide {
							opacity: 1 !important;
							margin-left: 4px !important;
							margin-right: 14px !important;
							width: 310px;
							height: 490px !important;
							padding-left: 10px;							
							
							@media #{$breakpoint-below-tablet} {
								//width: 8.8% !important;
								margin-left: 10px !important;
								margin-right: 10px !important;
								width: 250px;
								height: 443px !important;
							}
							@media #{$breakpoint-below-iphone5} {
								margin-left: 5px !important;
							}
							@media #{$breakpoint-below-desktop-above-tablet}{
								width: 250px!important;
							}
							
							& .store-card {
								height: 480px!important;
							}
							& .promos-card {
								box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
								position: relative;
								height: 480px;
								background-color: $Mashed-Potato-Gray;
								border-radius: 8px;
								text-align: center;
								display: flex;
								flex-direction: column;

								@media #{$breakpoint-below-tablet} {
									height: 440px !important;
								}

								& .promosImageText-background {
									height: 48.6%;
									object-fit: cover;
									border-top-left-radius: 3%;
									border-top-right-radius: 3%;
									@media #{$breakpoint-below-tablet} {
										height: 42.8%;
									}
								}

								& .promosOnlyImage-background {
									//height: 59%;
									border-radius: 3%;
									height: inherit;
									position: relative;
									background: linear-gradient(190.77deg, rgba(32, 33, 36, 0) 21.45%, rgba(32, 33, 36, 0.6) 98.01%);
								}

								& .promos-time-block {
									display: flex;
									flex-wrap: nowrap;
									overflow: hidden !important;
					
									& img.promos-time-icon {
										margin-top: 2.4%;
										width: auto;
										height: 14px;
										padding-right: 3%;
									}
								}

								& .promosRibbon-container {
									position: absolute;
									left: -12px;
									top: 20px;
									z-index: 1000;

									& .promos-ribbon {
										position: relative;
										max-width: 230px;
										min-width: 80px;
										// transform: scaleX(3);
									  }
									  & .promos-ribbon svg {
										width: 100%;
									  }

									& .ribbon-text {
										position: absolute;
										top: 4px;
										left: 12px;
										font-family: $National2Condensed;
										font-style: normal;
										font-weight: bold;
										font-size: 16px;
										line-height: 20px;
										letter-spacing: 1px;
										text-transform: uppercase;
										color: $white;	
										text-align: left;
										text-overflow: ellipsis;
										-webkit-line-clamp: 1;
										-webkit-box-orient: vertical;
										display: -webkit-box;
										overflow: hidden;
										max-width: 199px;
									}

									& .time-text {
										width: 150px;
										position: absolute;
										left: 4px !important;
										overflow: hidden !important;
										color: #E4002B;
									}
								}

								& .plp-promosRibbon-container{
									// @media #{$breakpoint-below-tablet} {
										top: 15px;
									// }
								}

								& .promosCard-content {
									display: flex;
									flex-direction: column;
									justify-content: space-evenly;
									align-items: center;
									margin-left: 20px;
    								margin-right: 20px;

									& .promosImageTextCard-title {
										height: 52px;
										width: 100%;
										font-family: $National2Condensed;
										font-size: 24px;
										font-weight: 700;
										line-height: 26px;
										color: $Brand-Black;
										text-transform: uppercase;
										overflow: hidden;
										text-overflow: ellipsis;
										display: flex;
										-webkit-line-clamp: 2;
										-webkit-box-orient: vertical;
										margin-top: 10px;
										margin-bottom: 5px;
										align-items: center;
										justify-content: center;
										padding: 5px;
									}
									& .promosCard-ellipsis-text {
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-line-clamp: 2;
										-webkit-box-orient: vertical;
									}
									
									& .promosOnlyTextCard-title {
										width: 95%;
										height: 110px;
										font-family: $National2Condensed;
										font-weight: 700;
										font-size: 30px;
										line-height: 30px;
										letter-spacing: 2px;
										margin-top: 50%;
										text-transform: uppercase;
										overflow: hidden;
										text-overflow: ellipsis;
										text-align: center;
										
										-webkit-line-clamp: 2;
										-webkit-box-orient: vertical;
										@media #{$breakpoint-below-desktop-above-tablet} {
											margin-top: 65%;
										}
									}

									.plp-empty-card-title{
										margin-top: 25%;										
										@media #{$breakpoint-below-tablet} {
											margin-left: 25%;
										}
									}

									& .plp-promosOnlyTextCard-title{
										margin-top: 57px;
										height: 52px;
										font-size: 24px;
										line-height: 26px;
									}

									& .promosOnlyTextCard-title.small-text {
										width: 65%;
									}

									& .validity {
										color: $Dark-Gray;
										margin-top: 9%;
										font-family: $National2Regular;
										font-weight: 400;
										font-style: normal;
										font-size: 14px;
										line-height: 24px;
										letter-spacing: -0.1px;
										text-align: center;
									}

									& .promosCard-text,
									.promosOnlyTextCard-text {
										width: 100%;
										height: 48px;
										font-family: $National2Regular;
										font-weight: 400;
										font-size: 14px;
										line-height: 24px;
										color: $Brand-Black;
										overflow: hidden;
										text-overflow: ellipsis;
										-webkit-line-clamp: 2;
										-webkit-box-orient: vertical;
										margin-bottom: 10px;
										display:-webkit-box;
										justify-content: center;
										align-items: center;
										padding: 5px;
										@media #{$breakpoint-below-tablet} {
											margin-bottom: 5px;
										}
									}
									& .view-all-text{
										width: 100%;
										height: 28px;
										justify-content: center;
										align-items: center;
										margin-bottom: 25px;
										display: flex;
									}

									& .promosOnlyTextCard-text {
										//height: 29.5%;
										//margin-top: 5%;
										margin-bottom: 23%;
										//width:80%;
									}
									& .promosOnlyTextCard-text.small-text {
										margin-top: 15%;
									}
									& .promosOnlyTextCard-text.emptycard{
										@media #{$breakpoint-below-tablet} {
											margin-left: -15%;
										}								
									}

									& .plp-promosOnlyTextCard-text{
										margin-top: 5px;
										margin-bottom: 10px;
									}

									& .plp-view-all-text {
										font-style: normal;
										font-weight: 500;
										font-size: 14px;
										line-height: 24px;
										text-align: center;
										margin-bottom: 15px;
										& .plp-offersViewAll-link {
                        color: #494949;
                        font-family: $National2Medium;
										}
									}

									& .promos-appled-icon{
										position: absolute;
										top:2px;
										left:10px;
										@media #{$breakpoint-above-desktop} {
											left: 5px;
										}
										@media #{$breakpoint-below-tablet} {
											left: -10px;
										}
									}

									& .plp-promos-appled-icon {
										left: 5px;
										@media #{$mobile-only} {
											left: 3px;
										}
										@media #{$breakpoint-ipad-12-pro-portrait} {
											left: 10px;
										}
									}

									& .promosButton-item {
										display: flex;
										justify-content: inherit;
										position: relative;
										//bottom: -1%;
										align-items: baseline;
										width: 100%;
										height: 44px;
										margin-bottom: 32px;
										@media #{$breakpoint-below-desktop} {
											//width: 92%;
										}
										& .promosView-details:hover,
										.promos-redeem:hover {
											cursor: pointer;
										}

										& .promosView-details {
											border: none;
											background: none;
											// outline: none;
											text-decoration: underline;
											padding: initial;
											font-family: $National2Medium;
											font-style: normal;
											font-weight: 500;
											font-size: 14px;
											line-height: 24px;
											color: $Dark-Gray;
											&:hover {
												background-color: $Light-Gray;
											}

											&:active {
												background-color: #cfd0d0;
											}

											&:focus-visible {
												background: #fff;
												border: 2px solid #2e4abd;
											}

											&:disabled {
												opacity: 0.5;
												background: transparent;
												cursor: not-allowed !important;
											}

											@media #{$breakpoint-below-tablet} {
												font-size: 12px;
												line-height: 22px;
											}
										}

										& .promos-redeem {
											font-family: $National2Regular;
											font-weight: 500;
											font-size: 0.875rem;
											//line-height: 24px;
											//width: 270px;
											&:disabled {
												color: $Brand-Black;
												cursor: not-allowed !important;
											}

											@media #{$breakpoint-below-tablet} {
												//padding: 10px 30px;
												//margin-left: 15px;
												//width: 210px;
											}
										}

	
									}

									& .plp-promosButton-item{
										width: 100%;
										margin-bottom: 32px;
										margin-top:17px;
										justify-content: space-around;

										@media #{$breakpoint-below-tablet} {
											width: auto;
										}

										& .btn-class{
											width: 200px !important;
											padding: 8px 40px;
											@media #{$breakpoint-below-tablet} {
												width: 260px;
												margin-left: 0;
											}
										}

										& .plp-start-order{
											@media #{$breakpoint-below-tablet} {
												width: 200px !important;
												margin-left: 40px !important;
											}
										}

										button{
											font-family: $National2Regular !important;
											width: 100%;
											@media #{$mobile-only} {
												font-style: normal;
												font-weight: 500;
												font-size: 14px;
												line-height: 24px;
											}
											@media #{$breakpoint-ipad-12-pro-portrait} {
                        width: 8.6rem;
											}
										}
										.button-contained {
											&[data-type="primary"] {
                                                width: auto;
												left: 12px;
												 @media #{$mobile-only}{
                          width: 200px;
												 }
												 @media #{$breakpoint-ipad-12-pro-portrait} {
                                                    min-width: 8rem;
													 left: -8px;
												 }
											 }
											&[data-type="tertiary"] {
                        width: 178px;
												left: 12px;
												@media #{$mobile-only}{
                          width: 200px;
												 }
												 @media #{$breakpoint-ipad-12-pro-portrait} {
													width: 164px;
													left: -8px;
												}
									  	}
										}
									}

									& .plp-toast-item{
										margin-bottom: 15px;
										height: 78px;

										& .promos-tost-container{
											margin-top: 0;
											padding: 15px 15px 35px 15px;
										}
										& .promos-tost-container.greenbg{
											background: #E6F3E6;
											height: 54px !important;
											margin-top:10px;
											@media #{$breakpoint-between-tablet-desktop} {
												margin-top: 12px !important;
											}
											
											@media #{$breakpoint-below-desktop-above-tablet} {
											margin-top: 0 !important;
											height: 78px !important;
											}
											@media #{$breakpoint-below-tablet} {
												height: 78px !important;
											}
										}
									
									}
								}

								& .plp-promosCard-content{
									justify-content: start;
									height: 100%;
								}

								& .promosFullImageCard-content {
									position:absolute;
									bottom: 0;
									//left: 17%;
									width: 87%;
									@media #{$breakpoint-below-tablet} {
										width: 85%;
										bottom: -2px;
										left: 0;	
									}								
									& .fullImageCardButton-item {
										.promos-redeem {
											&:hover {
												cursor: pointer;
												color: $Brand-Black;
												background-color: $white;
											}

											&:focus-visible {
												color: $Brand-Black;
												background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #fff;
												border: 2px solid transparent !important;
												box-shadow: 0 0 0 2px #ffbd15;
												border-radius: 200px;
											}

											&:active {
												cursor: pointer;
												color: $Brand-Black;
												background: linear-gradient(0deg, rgba(32, 33, 36, 0.2), rgba(32, 33, 36, 0.2)), #fff;
											}

											&:disabled {
												background-color: transparent;
												color:$Brand-Black;
												cursor: not-allowed;
											}
										}
										& .promosView-details,									
										.promos-redeem {
											font-family: $National2Regular;
											border-color: $white;
											color: $white;
											font-weight: 500;
											font-size: 0.875rem;
											//line-height: 24px;
										}
									}									
								}
								& .promos-applied {									

									.promos-appled-icon{
										position: relative!important;									
										// margin-right: 15px;
									}
								}

							}

							& .overflow-class{
								overflow: hidden;
							}

							& .plp-promo-card{
								.offers-store-image{
									transform: none!important;
								}
								.view-all-text{
									margin-bottom: 0 !important;
								}
							}
						}

						& .plp-slick-slide {
							width: 275px !important;
							margin-right: 0px !important;							
							.emptycard{
								@media #{$breakpoint-below-tablet} {
									margin-left: 20%!important;
								}								
							}
						}
					}
				}

				& .slick-next {
					right: 100px;
				}

				& .slick-next::before {
					font-family: unset;
					font-size: 36px;
					content: '\203A'; // \203A HTML Entry for ›
					color: #202124;
					background-color: white;
					//border-radius: 50%;
					display: inline-block;
					width: 48px;
					height: 48px;
					text-align: center;
					box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
					border-radius: 66px;
					//transform: rotate(-180deg);
				}

				//& .slick-next:focus::before {
					//outline: 1px auto -webkit-focus-ring-color;
				//}

				& .slick-next,
				.slick-prev {
					@media #{$breakpoint-below-tablet} {
						display: none;
					}
				}
			}

			& .plp-promosCarousal-list{
				& .slick-prev{
					top: 50%;
					left: 15px;
					z-index: 1;
				}

				& .slick-disabled{
					display: none;
				}
			}
		}
		.grid-container{
			@media #{$breakpoint-medium-desktop}{
				padding-left: 0.5rem !important;
			}
		}
	}


	& .plp-promosCard-container{
		margin-left: 0;
		padding-left: 1.25rem !important;
		@media #{$breakpoint-below-tablet} {
			align-items: start;
			text-align: start;
			padding-left: 0px !important;
		}
		& .promosCard-list{
			margin-left: 0;
			// @media #{$breakpoint-below-tablet} {	
			// 	margin-top:20px
			// }
			& .promosCarousal-list
			  .slick-list {
				& .slick-track {
					& .slick-slide {
						& .promos-card {
							& .promosFullImageCard-content{
								width: 82%;
							}
						}
					}
				}
			 }
		}
	}
	& .offersCard-fade{
		opacity: 0.5;
		@media #{$breakpoint-below-tablet} {
			opacity: 1!important;
		}
	}
	& .lastOffersCard-fade {
		opacity: 0.5!important;
		@media #{$breakpoint-below-tablet} {
			opacity: 1!important;
		}
	}

	& .headBottomTxt{
		@media #{$breakpoint-below-tablet} {
			margin-bottom:17px;
			margin-right: 0px;
		}
	}

	& .plp-headBottomTxt{
		@media #{$breakpoint-below-tablet} {
			margin-right: 0px;
		}
		// margin-bottom:5px;
	}

	& .headBottomParent{
		@media #{$breakpoint-below-tablet} {
			margin-top: 5px;
		}
	}

	& .offer-card-container{
		margin-top:0px;
	}
	& .fullImageCardButton-item{
		margin-bottom: 0px!important;		
	}
	
	& .login-card{
		display: flex;
		flex-direction: column;
		padding: 0;
		justify-content: center;
		margin-top: 0;
		background-color: $Brand-Red;
		border-radius: 8px;
	}
	& .empty-card{
		display: flex;
		flex-direction: column;
		padding: 0;
		justify-content: center;
		margin-top: 0;
		background: #F8F7F5;
		box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
		border-radius: 8px;
	}
	& .offer-login-text{		
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: 700;
		font-size: 30px;
		line-height: 38px;
		text-align: center;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: $white;
		width: 210px;
		height: 152px;
		margin-left: 50px;
   		margin-top: 125px;
		position:absolute;
		
		@media #{$breakpoint-below-tablet} {
			margin-top: 45%;		
		}
	}


	& .offer-login-btn-container {
		margin: 5% auto auto;
		color: $white;
		margin-top: 60px;

		& .login-btn {
			padding: 11px 35px;
			border-radius: 200px;
			height: 44px;
			text-align: center;
			align-items: center;
			flex-direction: row;
			cursor: pointer;
			outline: none;
			margin: auto;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			display: flex;
			background-color: transparent;
			border: 2px solid $white;
			color: $white;
		}
	}
	
	& .offersViewAll-link{
		text-decoration: none;
		color:$Brand-Black;
		cursor:pointer;
		font-family: $National2Regular;
		font-weight: 500;
		font-size: 14px;
		margin-bottom: 3px;
		@media #{$breakpoint-below-tablet} {
			font-weight: 400;			
		}
		@media #{$mobile-only} {
			font-family: $National2Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
			color: #494949;
		}

	}
	& .promosHeader-text{
		font-family: $National2Regular;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;		
		color:$Brand-Black;
		text-align: left;
		
		@media #{$breakpoint-below-tablet} {
			position: relative;
			margin-left: 0px;
			text-align: center;			
		}
		@media #{$breakpoint-ipad-12-pro-portrait} {
			margin-left: 320px;
		}
		@media #{$breakpoint-ipad-pro-12-landscape} {
			margin-left: 325px;
		}
	}

	& .plp-card-count{
		position: absolute;
		right: 20px;
		top: 2%;
		color: $Brand-Black;
		font-family: $HelveticaNeue;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 22px;
		letter-spacing:1px;
	}

	& .plp-view-all-promo-text{
		position: relative;
		bottom: 0px;
		left: 21%;
		display: flex;
			& .promosHeader-right-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
				color: #202124;
			}
				.injected-svg{
					@media #{$mobile-only}{
						margin-bottom: 7px !important;
					}
				}
	}

	& .promosHeader-right-text{
		font-family: $National2Medium;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;		
		color:$Brand-Black;
		margin-right: 10px;
		text-align: left;
		
	}
	& .store-card {
		display: flex;
		flex-direction: column;
		padding: 0;
		justify-content: center;
		margin-top: 0;
		
		border-radius: 8px;
		.offer-login-text {
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 30px;
			line-height: 38px;
			text-align: center;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $white;
			position: absolute;
			top:-10px;
			@media #{$breakpoint-below-tablet} {
				left: 9%;
			}
		}
	}
	.offers-store-image {
		object-fit: cover;
		width: 100%;
		transform: translateY(-6px) scaleY(0.98) scaleX(1) translateX(0px) !important;
		border-radius: 8px;
		@media #{$breakpoint-below-tablet} {
			transform: translateY(-25px) scaleY(0.89) scaleX(0.88) translateX(-15px) !important;
		}
	}

	.promos-redeem.promos-discout-button{
		width: 213px!important;
		margin-left: 45px;
		border:2px solid $Brand-Black;
		background: transparent;
		@media #{$breakpoint-below-tablet} {
			width: 155px!important;
			margin-left: 50px!important;
		}
		@media #{$breakpoint-ipad-12-pro-portrait} {
			width: 155px!important;
			margin-left: 50px!important;
		}
	}
	.promos-redeem.promos-discout-button.redButton{
		border:0px solid $Brand-Black;
		background: $Brand-Red;
		font-family: $National2Medium!important;
	}
	.offer-store-btn-container {
		position: absolute;
		top: 67%;
		color: $white;
		margin: auto;
		margin-left: 28%;
		
		button.store-btn {
			padding: 11px 45px;
			border-radius: 200px;
			height: 44px;
			text-align: center;
			align-items: center;
			flex-direction: row;
			cursor: pointer;
			outline: none;
			margin: auto;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			display: $d-flex;
			background-color: transparent;
			border: 2px solid #fff;
			color: #fff;

			&:hover {
				cursor: pointer;
				color: $Brand-Black;
				background-color: $white;
			}

			&:focus-visible {
				color: $Brand-Black;
				background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #fff;
				border: 2px solid transparent !important;
				box-shadow: 0 0 0 2px #ffbd15;
				border-radius: 200px;
			}

			&:active {
				cursor: pointer;
				color: $Brand-Black;
				background: linear-gradient(0deg, rgba(32, 33, 36, 0.2), rgba(32, 33, 36, 0.2)), #fff;
			}

			&:disabled {
				background: transparent;
				color: #dbdbdb;
				opacity: 0.5;
				border: 1.5px solid #dbdbdb;
				cursor: not-allowed;
			}
		}
	}

	.view-details-wrapper-modal {
		.modal-header{
			height: 60px !important;
			@media #{$mobile-only}{
				height: 40px !important;
			}
		}
		.header-title {
			position: relative;
			margin: unset !important;
			left: 20px;
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			color: #202124;
			@media #{$mobile-only}{
				font-family: $National2Medium !important;
				font-weight: 400 !important;
				font-size: 14px !important;
				top: 1px !important;
				color: #494949;
				line-height: 24px;
			}
			@media #{$breakpoint-below-tablet} {
				font-family: $National2TestMedium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				text-align: center;
				color: #494949;
				margin: auto !important;
				left: unset;
				text-transform:none;
			}
			@media #{$breakpoint-samsung-galaxy-s7-tab} {
				text-transform:none;
			}
		}
		.custom-modal-close {
			top: 20px;
			@media #{$breakpoint-below-tablet} {
				top: 12px !important;
			}
			@media #{$mobile-only} {
				top: 10px !important;
			}
		}
		.modal-content {
			width: 580px !important;
			height: 94% !important;
			left: 0% !important;
			top: 3% !important;
			position: relative!important;
			margin: auto;
			@media #{$breakpoint-below-tablet} {
				width: 100% !important;
				height: 100% !important;
				left: 0 !important;
				top: 0 !important;
				max-height: 100% !important;
			}
		}
		.delivery-content {
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0.5px;
			font-weight: 700;
			font-family: $National2Condensed;
			text-transform: uppercase;
			color: #202124;
			text-align: center;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			@media #{$breakpoint-below-tablet} {
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 32px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #202124;
				text-align: center;
			}
		}
		.view-details-img {
			display: flex;
			justify-content: center;
		}
		.veiw-detail-img-section {
			//position: absolute;
			height: 176px;
			width: 215px;
			//left: 206px;
			//top: 20px;
			//bottom: 20px;
			//border-radius: 0px;
			@media #{$breakpoint-below-tablet} {
				height: 176px;
				width: 215px;
				//left: 25%;
			}
		}
		.veiw-detail-section {
			margin-top: 20px;
			.review-text {
				position: relative;
				font-family: $National2Regular;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: -0.1px;
				color: #202124;
				top: 1.5px;
				left: 10px;
				@media #{$breakpoint-below-tablet} {
					bottom: -2px;
				}
			}
			.view-details-small-text {
				margin: auto;
				font-family: $National2Regular;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				text-align: center;
				letter-spacing: -0.1px;
				margin-top: 10px;
				margin-bottom: 20px;
				color: #494949;
				height: 72px;
			}
		}
		.list-item {
			margin: 10px;
		}
		.list-item li::first-letter{
			text-transform: uppercase;
		}
		.list-item li {
			padding: 5px;
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.1px;
			color: #494949;
		}
		.list-item li span{
			margin-left: -5px; 
			@media #{$breakpoint-iphone13} {
				margin-left: -1px;
			}
			@media #{$breakpoint-iphone-se-2020} {
				margin-left: -1px;
			}
		}
		.review-condition {
			padding: 15px 0px 15px 15px;
			background: #FFF6E3;
			position: relative;
			height: 54px;
			border-radius: 4px;
			margin: 10px;

			.condition-wrapper{
				display: flex;
    			flex-direction: row;
			}
		}
		.got-it-button-container {
			background: transparent;
			color: #202124;
			display: flex;
			text-align: center;
			height: 140px;
			align-items: center;

			//margin-top: 7%;
			//padding-bottom: 7%;
			.got-it-button {
				padding: 8px;
				width: 55.5%;
				border-radius: 200px;
				height: 44px;
				text-align: center;
				align-items: center;
				flex-direction: row;
				cursor: pointer;
				outline: none;
				margin: auto;
				margin-bottom: 43px !important;
				font-style: normal;
				font-family: $National2Medium;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				background-color: transparent;
				border: 1.5px solid #202124;
				color: #202124;
				@media #{$breakpoint-below-tablet} {
					width: 300px;
				}
				@media #{$breakpoint-samsung-galaxy-s7-tab} {
					width: 90%;
				}
			}
		}
	}
	.view-details-wrapper-modal.white-background .modal-body{
		background: #FFFFFF;
	}
	.multi-product-wrapper-modal {
		.header-title {
			position: relative;
			margin: unset !important;
			left: 20px;
			left: 4.08%;
			right: 10.51%;
			top: 3.29%;
			bottom: 92.33%;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 24px;
			display: flex;
			align-items: center;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			color: #202124;
			// @media #{$breakpoint-above-large-desktop} {
			// 	font-size: 34px !important;
			//   }
			@media #{$breakpoint-below-tablet} {
				font-family: $National2TestMedium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				text-align: center;
				color: #494949;
				margin: auto !important;
				position: unset;
			}
		}
		.modal-content {
			width: 76.5%;
    		left: 10%;
			@media #{$breakpoint-below-tablet} { 
				width: 100%;
    			left: 0%;
			}
		}
		.modal-body {
			padding: unset;
			.multi-product-wrapper {
				//margin: 60px 50px;
				@media #{$breakpoint-below-tablet} { 
					margin: unset;
				}
				.grid-container {
					grid-template-columns: 300px 300px 300px;
					@media (min-width:1920px) {
						transform: scale(1.5);
					}
				



					//padding: 40px 50px 60px 40px;
					.container {
						padding: unset;
						.grid {
							width: fit-content;
							cursor: pointer;
							background-color: $white;
							border-radius: 8px;
						}
					}
					@media #{$breakpoint-below-tablet} { 
						grid-template-columns: none;
					}
				}
			}
		}
		.custom-modal-close {
			@media #{$breakpoint-below-tablet} {
				top: 12px !important;
			}
		}
		.content {
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: 700;
			font-size: 38px;
			line-height: 43px;
			text-align: center;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: #202124;
			margin-bottom: 15px;
    		margin-top: 10px;
			@media (min-width:1920px) {
				margin-top: 40px;
				transform: scale(1.6);
				
			}
			@media #{$breakpoint-below-tablet} {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 24px;
				text-align: center;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #202124;
				margin: auto;
				width: 200px;
				margin-bottom: 30px;
				margin-top: 30px;
			}
		}
		.grid-container {
			display: grid;
			grid-template-columns: auto auto auto;
			padding: 0px 50px 60px 50px;
			row-gap: 30px;
			@media (min-width:1920px) {
				margin: 145px 90px 0px 300px;
}
				
			
			
			@media #{$breakpoint-below-tablet} {
				grid-template-columns: none;
				padding: 0px 0 64px 0;
				justify-content: center;
			}
		  }
		  .grid-item {
			background-color: rgba(255, 255, 255, 0.8);
			border: 1px solid rgba(0, 0, 0, 0.8);
			padding: 20px;
			font-size: 30px;
			text-align: center;
		  }
		  .grid {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
			grid-gap: 20px;
			align-items: stretch;
			cursor: pointer;
			background-color: $white;
			border-radius: 8px;
		  }
		  
		  .grid > article {
			border: 1px solid #ccc;
			box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
			width: 280px;
    		height: 298px;
			border-radius: 8px;
			@media #{$breakpoint-below-tablet} {
				width: 335px;
    			height: 344px;
			}
			.image-container {
			    display: flex;
				justify-content: center;
				@media #{$breakpoint-below-tablet} {
					width: 335px;
					height: 252px;
				}
				width: 280px;
				height: 210px;
			}
		  }
		.custom-modal-close > img{
			@media (min-width:1920px) {
				width: 100%!important;
			}
		}
		  .grid .description-txt {
			padding: 20px;
			font-family: $National2TestMedium;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			color: #202124;
			flex: none;
			order: 0;
			flex-grow: 0;
			margin: 5px 0px;
			padding-top: 0px;
		  }
		  .description-txt {
			@media #{$breakpoint-below-tablet} {
				width: 335px;
				height: 92px;
	
			}
			.kj-info {
				font-weight: normal !important;
				font-family: $National2Regular !important;
				&::before {
					content: ' •';
				}
			}
			height: 88px;
			  .desc {
					display: inherit;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
			  }
			  .kj {
				font-family: $National2Regular !important;
			  }
		  }
	}  
	
	& .promos-tost-container.greenbg{
		background: #E6F3E6;
		height: 54px!important;
		@media #{$breakpoint-above-tablet} {
		margin-top: -7px;
		}
		@media #{$breakpoint-below-tablet} {
			height: 78px!important;
		}
		& .promos-tost-text-container{
			display: flex;
			@media #{$breakpoint-below-tablet} {
				display: block;
			}

			& .promos-tost-text{
				padding-right: 5px;
			}
		}
	}
	& .promos-checked-icon{
		cursor: pointer;
		@media #{$breakpoint-below-desktop-above-tablet} {
			margin-right: 50px;
		}
		
	}

	& .plp-promos-checked-icon{
		// margin-left: 45px;
		@media #{$breakpoint-below-desktop-above-tablet} {
			margin-right: 15px;
		}

		button {
			font-family: $National2Regular !important;
		}
	}
	
	& .promos-tost-container{
		height: 78px;
		width: 270px;
		left: 0px;
		top: 0px;
		border-radius: 3px;
		padding:15px;
		margin-top: -20px;
		background: #FFF6E3;
		z-index: 1;
		@media #{$breakpoint-below-tablet} {
			width: 100%;
			position: absolute;	
			
		}
		@media #{$breakpoint-below-desktop-above-tablet} {
			//width: 210px;
		}
		& .promos-tost-icon{
			position: absolute;
		}
		& .green-icon{
			margin-top: -3px;
		}
		& .promos-store-image{
			margin-top: -3px;
		}
		& .plp-promos-store-image{
			margin-top: 0px;
		}
		& .promos-tost-text-container{
			position: static;
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			line-height: 22px;
			letter-spacing: 0px;
			color: #202124;
			flex: none;
			order: 1;
			flex-grow: 0;
			margin-left: 25px;
			text-align: left;

			& .promos-tost-link{
				font-weight: 700;
				cursor: pointer;
			}
		}
	}
	
}

.plp-promos-container{
	@media #{$breakpoint-between-tablet-desktop} {
		width: 888px;
		height: auto;
	}
	@media #{$breakpoint-above-tablet} {
		margin-bottom: 40px;
	}
}

.promos-container {
	.plp-promosCard-container{
		.plp-promosContainerHeader-items{
			margin-right: 1%;
	 	.plp-view-all-promo-link{
			@media #{$breakpoint-below-desktop} {
				margin-right: -1%;
			}
			@media #{$breakpoint-desktop-small} {
				margin-right: -10%;
			}
		}
	}
		.promosCard-list {
	        .plp-promosCarousal-list {
				.slick-slider{
					width: 112%;
					//display: flex;
					*{
						outline: none;
					}
					@media #{$breakpoint-between-tablet-desktop} {
						width: 114%;
					}
				}
				.slick-list {
					padding-left: 0;

					@media #{$breakpoint-below-tablet} {
						padding-left: 1rem;
					}
					.slick-track {
						@media #{$breakpoint-below-tablet} {
							height: 330px;
						}
						.slick-slide {
							width: 275px !important;
							@media #{$breakpoint-below-tablet} {
								width: 300px !important;
								margin-left: -25px !important;
    							margin-right: 50px !important;
							}							
							@media #{$breakpoint-below-desktop-above-tablet}{
								width: 250px!important;
							}
							@media #{$breakpoint-desktop-small} {
								width: 203px !important;
							}
						}
			    }
		}

				.slick-next{
					//left: 850px;
					top: 45%;
					right: 120px;
					@media #{$breakpoint-between-tablet-desktop} {
						right: 130px;
					}
					@media #{$breakpoint-above-desktop} {
						right: 105px;
						top: 45.5%;
					}
					@media #{$breakpoint-desktop-small} {						
						right: 50px;
					}
               
				}
				
				.slick-prev{
					top: 45%;
					left: 5px;
					z-index: 1;
					@media #{$breakpoint-desktop-small} {
						left: 2px;
					}					
				}

				& .slick-arrow:focus::before {
					outline: none;
				}
			}
		}
	}
} 

.promos-rewards-warning-msg .modal-content{
	.modal-body {
		.alert-title {
			width: 240px;
			max-width: 215px;
 			margin-top: 15px;
		}
	}
	height: 335px!important;
	width: 580px!important;
	@media #{$breakpoint-below-tablet}{
        width: 100%!important;
    }
}
.card-lable{
	position: absolute;
	right: -6.02%;
	bottom: 28.57%;
	background: #E4002B;
	border: 1.26101px solid #E4002B;
	box-sizing: border-box;
	box-shadow: 2.02101px 2.02101px 1.01051px rgba(32, 33, 36, 0.24);
	height: 30px;
	width: 141px;
	left: 0px;
	top: 0px;
	border-radius: 0px;
	z-index: 1;
	& .promosLable-text {
		left: 4px !important;
		top: 3px;
		overflow: hidden !important;
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: $white;

	}
}

.redBg {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color:$Brand-Red;
    height: 68px;
	margin-top: 15px;
	@media #{$breakpoint-below-tablet} {
		height: 118px!important;
		margin-top: 20px;
		& .whiteButton{
			width: 242px;
		}
	}
	.set-location-btn {
		margin: auto !important;
		font-family: $National2Medium;
	}
	
}

.plp-headBottom{
	@media #{$breakpoint-below-tablet} {
		height: 118px !important;
	}

	button {
		span {
			font-family: $National2Medium !important;
		}
	}
}
.alert-toast {
	width: 298px;
	top: 451px;

	& .text-img-div {
		text-align: $center;
	}

	& .text {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 22px;
		font-family: $National2Regular;
		margin-left: 5px;
	}
}

.promos-landingPage-container {
	& .promosCard-container {
		& .promos-Icon {	
			margin-left: 0%	;

		@media #{$breakpoint-above-medium-tablet}{ 
			margin-left: 5.7% !important;		
	   }
	}
	  & .promosContainerHeader-items {
	    	margin-left: 0%	;

		@media #{$breakpoint-above-medium-tablet}{ 
	    	margin-left: 7% !important;
		}
		& .view-all-text {
		   margin-right: -10%;
			@media #{$breakpoint-below-desktop} {
				margin-right: 8%;
			}
			  
		  @media #{$breakpoint-below-tablet} {
			margin-right: 0;
			height: 28px;
			margin-top: 15px;
			margin-bottom: 20px;
		  }
		}
  
		@media #{$breakpoint-below-tablet} {
		  flex-direction: column;
		}
  
		& .promosContainer-header {
		  font-family: $National2Condensed;
		  text-transform: uppercase;
		  letter-spacing: 1px;
		  font-size: 38px;
		  line-height: 43px;
		  font-weight: bold;
  
		  @media #{$breakpoint-below-tablet} {
			width: 100%;
			font-size: 24px;
			line-height: 32px;
			margin-bottom: 20px;
		  }
		}
  
		& .promosViewAll-link {
		  font-family: $National2Regular;
		  font-weight: 500;
		  font-size: 14px;
		  line-height: 24px;
		  color: $white;
		  text-decoration: none;
		  margin-right: 10px;
  
		  &:hover {
			cursor: pointer;
		  }
		}
  
		& .promosViewAlldeals-arrow {
		  display: inline-block;
		}
	  }
  
	  & .promosCard-list-container {
		display: flex;
		padding: 5px;
		flex-direction: column;
  
		@media #{$breakpoint-below-tablet} {
		  width: 100%;
		}
  
		& .promos-list-card-container {
		  margin-bottom: 3%;
		  @media #{$breakpoint-below-tablet} {
			margin-left: 10px !important;
			margin-right: 10px !important;
			width: 250px !important;
			height: 443px !important;
		  }
  
		  & .list-card {
			box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
			position: relative;
			height: 450px;
			background-color: $Mashed-Potato-Gray;
			border-radius: 8px;
			text-align: center;
			display: flex;
			flex-direction: column;
  
			@media #{$breakpoint-below-tablet} {
			  height: 440px !important;
			}
  
			& .promosImageText-background {	
			  height: 48.6%;
			  object-fit: cover;
			  border-top-left-radius: 3%;
			  border-top-right-radius: 3%;
			  @media #{$breakpoint-below-tablet} {
				height: 42.8%;
			  }
			}
  
			& .promosOnlyImage-background {
			  border-radius: 3%;
			  height: inherit;
			  position: relative;
			  background: linear-gradient(190.77deg, rgba(32, 33, 36, 0) 21.45%, rgba(32, 33, 36, 0.6) 98.01%);
			}
  
			& .promosRibbon-container {
			  position: absolute;
			  left: -12px;
			  top: 12px;
			  z-index: 1000;
  
			  & .promos-ribbon {
				position: relative;
				max-width: 230px;
				min-width: 75px;
			}
			  & .promos-ribbon svg {
				width: 100%;
			  }
  
			  & .ribbon-text {
				position: absolute;
				top: 4px;
				left: 12px;
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $white;
			  }
  
			  & .promosTimer-text {
				width: 150px;
				position: absolute;
				left: 4px !important;
				top: 3px;
				overflow: hidden !important;
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $Brand-Red;
  
				& .promos-timer {
				  display: inline-block;
				  position: absolute;
				  left: 0px;
				  top: 4px;
				}
			  }
			}
			
			& .promosFullImageCard-content {
				
			  & .fullImageCardButton-item {
				.promos-redeem {
				  &:hover {
					cursor: pointer;
					color: $Brand-Black;
					background-color: $white;
				  }
  
				  &:focus-visible {
					color: $Brand-Black;
					background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #fff;
					border: 2px solid transparent !important;
					box-shadow: 0 0 0 2px #ffbd15;
					border-radius: 200px;
				  }
  
				  &:active {
					cursor: pointer;
					color: $Brand-Black;
					background: linear-gradient(0deg, rgba(32, 33, 36, 0.2), rgba(32, 33, 36, 0.2)), #fff;
				  }
  
				  &:disabled {
					background-color: transparent;
					color: $Brand-Black;
					cursor: not-allowed;
				  }
				}
				& .promosView-details,
				.promos-redeem {
				  font-family: $National2Medium;
				  border-color: $white;
				  color: $white;
				  font-weight: 500;
				  font-size: 0.875rem;
				  //line-height: 24px;
				}
			  }
			}
		  }
		}
	  }
	}
	.offers-store-image {
		object-fit: cover;
		width: 100%;
		transform: translateY(-6px) scaleY(0.98) scaleX(1) translateX(0px) !important;
		border-radius: 8px;
		@media #{$breakpoint-below-tablet} {
			transform: translateY(-25px) scaleY(0.89) scaleX(1) translateX(0px) !important;
		}
	}
	.sc-dlnjwi {
		.headBottomParent {
			.button {
				@media #{$breakpoint-below-tablet} {
					padding: 11px 70px;
				}
			}
		}
	}
	.view-all-text {
	  text-decoration: underline;
	}
	.promos-load-more-footer {
		width: 1024px;
		@media #{$breakpoint-below-tablet} {
			width: 100%;
		}
		.load-more-container {
			width: fit-content;
			margin: auto;
			display: flex;
			flex-direction: column;
			padding-bottom: 50px;
	
			@media #{$breakpoint-below-tablet} {
				margin-top: 15px;
				padding-bottom: 30px;
			}
	
			button.load-btn {
				font-family: $National2Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				padding: 10px 40px;
				border: 1.5px solid $Brand-Black!important;
			}
	
			.page-desc {
				width: fit-content;
				margin: 10px auto 0;
				font-family: $National2Test, $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				color: $Dark-Gray;
			}
		}
		
	}
	.lto-banner-promos-rewards-page {
		margin-top: 20px;
		.lto-banner-container {
			margin: 30px 0px 100px;
			@media #{$breakpoint-below-tablet} {
				margin: 60px 0px 0px;
			}
		}
	}
	.promos-reward-nil-container {
		background-color: #fff;
		margin-bottom: 20px;
	
		&.no-scroll {
			overflow: hidden !important;
		}
	
		.nil-card-container {
			display: flex;
			flex-wrap: wrap;
			margin-top: 50px;
			margin-left: 43px;
			@media #{$breakpoint-below-tablet} {
				margin-top: 0 !important;
				margin-left: 15px;
			}

			& .store-card {
				height: 492px!important;
				border: unset;
			}

			.nilCard-main-container {
				right: 0%;
				bottom: 0%;
				background: #F8F7F5;
				box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
				height: 474px;
				width: 310px;
				left: 0px;
				top: 0px;
				border-radius: 8px;
				position: relative;
				margin-right: 2.3%;
				margin-bottom: 3%;
				@media #{$breakpoint-below-tablet} {
					margin-bottom: 8%;
				}

				.thats-all-text {
					position: absolute;
					left: 16.13%;
					right: 16.13%;
					top: 25.32%;
					bottom: 50.63%;

					font-family: $National2Condensed;
					font-style: normal;
					font-weight: 700;
					font-size: 30px;
					line-height: 38px;
					/* or 127% */

					text-align: center;
					letter-spacing: 1px;
					text-transform: uppercase;

					color: #202124;
					height: 114px;
					width: 210px;					
				}
				.back-again-text {
					position: absolute;
					left: 16.13%;
					right: 16.13%;
					top: 58.86%;
					bottom: 31.01%;

					font-family: $National2Regular;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 24px;
					text-align: center;
					letter-spacing: -0.1px;
					color: #202124;
					height: 48px;
					width: 210px;
				}
			}
		}
	}
	.promos-reward-page-container {
		background-color: #fff;
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		margin-left: 1%;
		@media #{$breakpoint-above-medium-tablet}{ 
			margin-left: 7%!important;		
	   }
		@media #{$breakpoint-below-tablet} {
			justify-content: center;
		}
		&.no-scroll {
			overflow: hidden !important;
		}
	
		.promos-reward-cards-container {
			& .store-card {
				height: 495px!important;
				border: unset;
			}
			margin-top: 12.5px;
			margin-bottom: 12.5px;
			margin-left: 4px;
			margin-right: 14px;
			@media #{$breakpoint-below-tablet} {
				margin-top: 5px !important;
			}
			& .promos-reward-card-container {
				width: 310px;
				box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
				position: relative;
				height: 480px;
				background-color: $Mashed-Potato-Gray;
				border-radius: 8px;
				text-align: center;
				display: flex;
				flex-direction: column;

				@media #{$breakpoint-below-tablet} {
					height: 440px !important;
					
				}
				@media #{$breakpoint-ipad-12-pro-portrait} {
					width: 250px;
				}
				.offer-login-text {
					@media #{$breakpoint-below-tablet} {
						margin-left: 45px;
						text-align: center;
						
					}
				}
				.offer-store-btn-container {
					@media #{$breakpoint-below-tablet} {
						margin-left: 80px;
					}
				}

				& .promosImageText-background {
					height: 48.6%;
					object-fit: cover;
					border-top-left-radius: 3%;
					border-top-right-radius: 3%;
					@media #{$breakpoint-below-tablet} {
						height: 42.8%;
					}
				}

				& .promosOnlyImage-background {
					border-radius: 3%;
					height: inherit;
					position: relative;
					background: linear-gradient(190.77deg, rgba(32, 33, 36, 0) 21.45%, rgba(32, 33, 36, 0.6) 98.01%);
				}

				& .promos-time-block {
					display: flex;
					flex-wrap: nowrap;
					overflow: hidden !important;
	
					& img.promos-time-icon {
						margin-top: 2.4%;
						width: auto;
						height: 14px;
						padding-right: 3%;
					}
				}

				& .promosRibbon-container {
					position: absolute;
					left: -12px;
					top: 12px;
					z-index: 1000;

					& .promos-ribbon {
						position: relative;
						max-width: 230px;
						min-width: 75px;
						// transform: scaleX(3);
					  }
					  & .promos-ribbon svg {
						width: 100%;
					  }

					& .ribbon-text {
						position: absolute;
						top: 4px;
						left: 12px;
						font-family: $National2Condensed;
						font-style: normal;
						font-weight: bold;
						font-size: 16px;
						line-height: 20px;
						letter-spacing: 1px;
						text-transform: uppercase;
						color: $white;	
						text-align: left;
						text-overflow: ellipsis;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						display: -webkit-box;
						overflow: hidden;
						max-width: 199px;
					}

					& .time-text {
						width: 150px;
						position: absolute;
						left: 4px !important;
						overflow: hidden !important;
						color: #E4002B;
					}
				}

				& .promosCard-content {
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					align-items: center;
					margin-left: 20px;
					margin-right: 20px;
					
					& .promosImageTextCard-title {
						height: 52px;
						width: 100%;
						font-family: $National2Condensed;
						font-size: 24px;
						font-weight: 700;
						line-height: 26px;
						color: $Brand-Black;
						text-transform: uppercase;
						overflow: hidden;
						text-overflow: ellipsis;
						display: flex;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						margin-top: 10px;
						margin-bottom: 5px;
						align-items: center;
						justify-content: center;
						padding: 5px;
					}
					& .promosCard-ellipsis-text {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
					
					& .promosOnlyTextCard-title {
						width: 95%;
						height: 110px;
						font-family: $National2Condensed;
						font-weight: 700;
						font-size: 30px;
						line-height: 30px;
						letter-spacing: 2px;
						margin-top: 51%;
						text-transform: uppercase;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: center;						
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						@media #{$breakpoint-below-tablet} {
							margin-top: 30%;
						}
					}
					& .promosOnlyTextCard-title.small-text {
						width: 65%;
					}

					& .validity {
						color: $Dark-Gray;
						margin-top: 9%;
						font-family: $National2Regular;
						font-weight: 400;
						font-style: normal;
						font-size: 14px;
						line-height: 24px;
						letter-spacing: -0.1px;
						text-align: center;
					}

					& .promosCard-text,
					.promosOnlyTextCard-text {
						width: 100%;
						max-height: 68px;
						min-height: 48px;
						font-family: $National2Regular;
						font-weight: 400;
						font-size: 14px;
						line-height: 24px;
						color: $Brand-Black;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						margin-bottom: 10px;
						display:-webkit-box;
						justify-content: center;
						align-items: center;
						// padding: 5px;
						@media #{$breakpoint-below-tablet} {
							margin-bottom: 5px;
						}
					}
					& .view-all-text{
						width: 100%;
						height: 28px;
						justify-content: center;
						align-items: center;
						margin-bottom: 25px;
						display: flex;
					}

					& .promosOnlyTextCard-text {
						margin-bottom: 20%;
					}					

					& .promos-appled-icon{
						position: absolute;
						top:2px;
						left:10px;
						@media #{$breakpoint-above-desktop} {
							left: 20px;
						}
					}

					& .promosButton-item {
						display: flex;
						justify-content: inherit;
						position: absolute;
						bottom: -1%;
						align-items: baseline;
						width: 100%;
						height: 44px;
						margin-bottom: 35px;
						@media #{$breakpoint-below-desktop} {
							width: 92%;
						}
						.promos-tost-icon  {
							img {
								display: block;	
							}
						}
					
						& .promosView-details:hover,
						.promos-redeem:hover {
							cursor: pointer;
						}

						& .promosView-details {
							border: none;
							background: none;
							text-decoration: underline;
							padding: initial;
							font-family: $National2Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 24px;
							color: $Dark-Gray;
							&:hover {
								background-color: $Light-Gray;
							}

							&:active {
								background-color: #cfd0d0;
							}

							&:focus-visible {
								background: #fff;
								border: 2px solid #2e4abd;
							}

							&:disabled {
								opacity: 0.5;
								background: transparent;
								cursor: not-allowed !important;
							}

							@media #{$breakpoint-below-tablet} {
								font-size: 12px;
								line-height: 22px;
							}
						}

						& .promos-redeem {
							font-family: $National2Regular;
							font-weight: 500;
							font-size: 14px;
							line-height: 24px;
							width: 270px!important;
							&:disabled {
								color: $Brand-Black;
								cursor: not-allowed !important;
							}							
						}

						
					}
				}

				& .plp-promosCard-content{
					justify-content: start;
					height: 100%;
				}

				& .promosFullImageCard-content {
					& .fullImageText-item{
						z-index: 1;
						margin-top: -200px;
					}
					& .fullImageCardButton-item {
						.promos-redeem {
							&:hover {
								cursor: pointer;
								color: $Brand-Black;
								background-color: $white;
							}

							&:focus-visible {
								color: $Brand-Black;
								background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #fff;
								border: 2px solid transparent !important;
								box-shadow: 0 0 0 2px #ffbd15;
								border-radius: 200px;
							}

							&:active {
								cursor: pointer;
								color: $Brand-Black;
								background: linear-gradient(0deg, rgba(32, 33, 36, 0.2), rgba(32, 33, 36, 0.2)), #fff;
							}

							&:disabled {
								background-color: transparent;
								color:$Brand-Black;
								cursor: not-allowed;
							}
						}
						& .promosView-details,					
						.promos-redeem {
							font-family: $National2Medium;
							border-color: $white;
							color: $white;
							font-weight: 500;
							font-size: 0.875rem;
							//line-height: 24px;
						}
					}
				}
			}
		}
	}

	.medium-card-offers-deals{
		display: block!important;
	}
	.offers-deals-card-container{
	  display: block!important;
   }
   
  }
  

	
.promos-rewards-warning-msg{
	z-index: 9999!important;
}  
.promos-rewards-warning-msg .alert-cancel{
    width: 160px!important;
}

.promos-rewards-warning-msg .alert-btn-container .alert-confirm{
    width: 160px!important;
}

.promos-rewards-warning-msg .alert-model-view-root{
    @media #{$breakpoint-below-tablet}{
        height: auto!important;
    }
}
.promos-rewards-toast{
	width: 210px!important;
	margin: auto!important;
	& .Toastify__close-button{
		display: none;
	}
	& .text{
		font-size: 12px;
		font-family: $National2Regular;
		font-weight: 500;
	}
}


@media screen and (min-width:540px) and (max-width:912px){
	.promos-rewards-toast-pos{
		left: 50% !important;
	}
}	

.multi-product-image{
		width: 90%;
		margin-bottom: 15px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
}

	.ribbon-text-svg{
		position: relative;
        top: 4px;
        left: -1px;
		font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $white;  
        text-align: left;
        background: #e4002b;
        padding: 5px 0px 5px 10px;
		box-shadow: rgba(32, 33, 36, 0.24) 0px 1.5px 0px 0px;
                		
		&:after{
			content: "";
			position: absolute;
			border-color: #e4002b transparent #e4002b #e4002b;
			border-style: solid;
			left: 100%;
			width: 0px;
			height: 0px;
			top: 0;
			border-width: 15px;
			box-shadow: rgba(32, 33, 36, 0.24) 0px 1.5px 0px 0px;
		}
	}
	.angle-down{
		position: absolute;
		width: 0px;
		height: 0px;
		border-top: 12px solid #e4002b;
		border-bottom: 0px solid #190d0d00;
		border-left: 14px solid transparent;
		left: -1px;
		top: 31px;
		border-right: 0px solid #1b181900;
	  }

.promos-container .store-card-container{
	max-width: 31%;
	@media #{$mobile-only} {
		max-width: 90%;
	}
}
.promos-container .placeholder{
	margin-top:0;
	justify-content: flex-start;
	width: 100%;
	@media #{$mobile-only} {
		justify-content: center;
		height: 150px;
		overflow: hidden;
	}
}
.plp-promos-loading .promo-section-container{
	min-height: 300px;
	max-height: 510px;
	height: auto;
	@media #{$mobile-only} {
		justify-content: center;
		min-height: 150px;
		overflow: hidden;
	}
}
.plp-promos-loading{
	height: 300px!important;
}
